<!-- Underneath is a test -->
<div class="container d-flex text-center justify-content-center px-0 pt-3">
    <nav class="navbar navbar-expand-lg  fix-width fix-navbar px-0">
        <a class="" href="#"><img src="assets/images/Outdoor-Logo-B.png" style="width: 100%;" alt="SJCOE Logo"></a>
        <button class="navbar-toggler bg-light" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>
        <div [ngClass]="{'hide': isMenuCollapsed, 'show': !isMenuCollapsed}" class="super-nav navbar-collapse collapse">
            <span class="pointer super-x" (click)="isMenuCollapsed = !isMenuCollapsed">
                <p class="xSpot">  <fa-icon [icon]="faTimes" style="font-size: 50px; color: #fff;" alt="Close Menu"></fa-icon></p>
            </span>
            <ul class="navbar-nav ml-auto">
                <!-- if using a dropdown add this this a tags => ngbDropdownToggle -->
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" (click)="isMenuCollapsed = !isMenuCollapsed" id="dropdownBasic2" SmoothScroll [offset]="-50"
                        href="DurhamFerry#fieldTripEvents">Field Trips</a>
                </ul>
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" (click)="isMenuCollapsed = !isMenuCollapsed" id="dropdownBasic1" SmoothScroll [offset]="-50"
                        href="DurhamFerry#summerCampEvents">Summer Camps</a>
                </ul>
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" (click)="isMenuCollapsed = !isMenuCollapsed" id="dropdownBasic1" SmoothScroll [offset]="-50"
                        href="DurhamFerry#Facilities">Facilities To Rent</a>
                </ul>

                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" (click)="isMenuCollapsed = !isMenuCollapsed" id="dropdownBasic3" SmoothScroll [offset]="-50"
                        href="DurhamFerry#ProLearning">Professional Learning</a>
                </ul>

                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2" target="_blank" href="https://www.sjcoescience.org/" >SJCOE STEM Programs
                    </a>
                    
                </ul>
     <!--            <ul class="d-inline-block pt-2">
                    <div ngbDropdown class="dropdown">
                        <button class="btn btn-secondary programBtn" id="dropdownMenuButton" ngbDropdownToggle><i>Choose a
                                Program</i></button>
                        <div class="dropdown-content">
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic2"
                            
                                class="academy-menu dropdown-menu-right mx-0">
                                <li>
                                    <a href="/DurhamFerry"><button class="dropdownBtn-color" ngbDropdownItem>Durham
                                            Ferry</button></a>
                                </li>
                                <li>
                                    <a href="/SkyMountain"><button class="dropdownBtn-color" ngbDropdownItem>Sky
                                            Mountain</button></a>
                                </li>
                            </div>
                        </div>
                    </div>
                </ul> -->
            </ul>
        </div>
    </nav>
</div>
